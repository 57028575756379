import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import SalesOverview from '../Dashboard/components/SalesOverview';
import './index.css';
import image1 from '../../../assets/images/result/Screenshot 2024-03-08 192716.png';
import image2 from '../../../assets/images/result/Screenshot 2024-03-08 194425.png';

const StudentResult = (props) => {
  const [pdfGenerated, setPdfGenerated] = useState(false);
  const invoiceContainerRef = useRef(null);

  const captureInvoice = async () => {
    const invoiceContentElement = invoiceContainerRef.current;

    if (!invoiceContentElement) {
      console.error('Element with ref "invoiceContainerRef" not found.');
      return;
    }

    try {
      const canvas = await html2canvas(invoiceContentElement, {});
      const imgData = canvas.toDataURL('image/png');
      const pageWidth = 210;
      const pageHeight = 297;
      const height = (canvas.height * pageWidth) / canvas.width;
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'PNG', 0, 0, pageWidth, height);

      // Save the PDF to state
      setPdfGenerated(pdf);
    } catch (error) {
      console.error('Error capturing element:', error);
    }
  };

  const handleDownload = () => {
    if (pdfGenerated) {
      pdfGenerated.save('invoice.pdf');
    } else {
      console.error('PDF not generated yet. Call captureInvoice first.');
    }
  };

  useEffect(() => {
    // Use setTimeout to give SalesOverview component time to render
    setTimeout(() => {
      captureInvoice();
    }, 1000); // Adjust the delay as needed
  }, []);

  return (
    <div>
      <button onClick={handleDownload}>Download PDF</button>
      <div id="invoice_container" ref={invoiceContainerRef}>
        {/* Your content to be captured for the PDF */}
        <p>StudentResult Content</p>
        <SalesOverview />

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div>
                            6 Months Result
                        </div>
                        <div className="container-fuild">
                            <div className="row first">
                                <div className="col-md-3">
                                    <div>
                                        94
                                    </div>
                                    <div>
                                        result
                                    </div>
                                    <div>
                                        2
                                    </div>
                                    <div>
                                        Pass
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div>
                                        96
                                    </div>
                                    <div>
                                        result
                                    </div>
                                    <div>
                                        2
                                    </div>
                                    <div>
                                        Pass
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        Total Numbers
                                    </div>
                                    <div>
                                        Takdirat
                                    </div>
                                    <div>
                                        Grade in Class
                                    </div>
                                    <div className="bordertop">
                                        Result
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {/* <img src={image1} alt="" width="100%" /> */}
                    </div>
                    <div className="col-md-3">
                        <div className="container-fliud">
                            <div className="row">
                                <div className="col-md-6 rightborder bottomborder">
                                        Seven
                                </div>
                                <div className="col-md-6 rightborder bottomborder">
                                        Class
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 rightborder bottomborder">
                                        Abdul-Sami
                                </div>
                                <div className="col-md-6 rightborder bottomborder">
                                        Name
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 rightborder bottomborder">
                                        Abdul-Rahim
                                </div>
                                <div className="col-md-6 rightborder bottomborder">
                                        Father Name
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 rightborder bottomborder">
                                        75
                                </div>
                                <div className="col-md-6 rightborder bottomborder">
                                        Result Number
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt">
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col-md-2">
                                leave
                            </div>
                            <div className="col-md-2">
                                Sick 
                            </div>
                            <div className="col-md-2">
                                Absent
                            </div>
                            <div className="col-md-2">
                                Present
                            </div>
                        </div>
                        <div className="row mt">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div> 
                            </div>
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Six Months
                            </div>
                        </div>
                        <div className="row mt">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div> 
                            </div>
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Year
                            </div>
                        </div>
                        <div className="row mt">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div> 
                            </div>
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    1
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Total
                            </div>
                        </div>
                        <div className="row mt">
                            <div className="col-md-4">
                                Year
                            </div>
                            <div className="col-md-4">
                                Six Months
                            </div>
                        </div>
                        <div className="row mt">
                            <div className="col-md-4">
                                <div className="boxshadow">
                                    
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="boxshadow">
                                    
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                    Class Teacher
                            </div>
                        </div>
                        <div className="row mt">
                            <div className="col-md-4">
                                <div className="boxshadow">
                                    
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="boxshadow">
                                    
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                    Principal
                            </div>
                        </div>
                        <div className="row mt">
                            <div className="col-md-4">
                                <div className="boxshadow">
                                    
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="boxshadow">
                                    
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Student Parent
                            </div>
                        </div>
                        <div className="row mt">
                            <div className="col-md-4">
                                <div className="boxshadow">
                                    
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="boxshadow">
                                    
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                    Darul ulome Final Date and Stamp
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        {/* <img src={image2} alt="" width="100%" /> */}
                    </div>
                    <div className="col-md-5 subjectsrows">
                        <div className="row">
                            <div className="col-md-2 rightborder bottomborder">
                                Total
                            </div>
                            <div className="col-md-3 rightborder bottomborder">
                                Full Year
                            </div>
                            <div className="col-md-3 rightborder bottomborder">
                                Six Months
                            </div>
                            <div className="col-md-4">
                                Subjects
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Maths
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Maths
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Maths
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Maths
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Maths
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Maths
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Maths
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="boxshadow">
                                    98
                                </div>
                            </div>
                            <div className="col-md-4 rightborder bottomborder">
                                Maths
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Rest of your content */}
      </div>
    </div>
  );
};

StudentResult.propTypes = {};

export default StudentResult;
