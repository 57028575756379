import { Navigate } from "react-router";
import Login from "../veiws/Website/authentication/Login/login";
import Registration from "../veiws/Dashboard/authentication/Registration/registration";
import HomePage from "../veiws/Website/HomePage/HomePage";
import { uniqueId } from "lodash";
import { IconAperture, IconCopy, IconLayoutDashboard,  IconLogin, IconMoodHappy, IconTypography, IconRegistered, IconUserPlus } from '@tabler/icons';
import TechRegistration from "../veiws/Dashboard/authentication/TeacherReg/registration";
import CustomizeRolls from "../veiws/Dashboard/CustomizeRolls/CustomizeRolls";
import StudentAttendance from "../veiws/Dashboard/Attendance/studentAttendance/studentAttendance";
import TeacherAttendance from "../veiws/Dashboard/Attendance/TeacherAttendance/TeacherAttendance";
import Subjects from "../veiws/Dashboard/Subjects/Subjects";
import Fee from "../veiws/Dashboard/collection/Fee/Fee";
import StudentReport from "../veiws/Dashboard/report/Student/Student";
import TeacherReport from "../veiws/Dashboard/report/Teacher/Teacher";
import Salary from "../veiws/Dashboard/collection/salary/salary";
import Expense from "../veiws/Dashboard/Expense/Expense/Expense";
import Student from "../veiws/Dashboard/report/Student/Student";
import { AddCard, Api, AddAlert, AppRegistration, Assessment, Bloodtype, InstallMobile, CallSplit, DataSaverOn, Dialpad, GradeOutlined, VerifiedUserRounded, StickyNote2Rounded, FlightClassOutlined, Games, Subject } from "@mui/icons-material";
import Donation from "../veiws/Dashboard/Expense/Donation/Donation";
import Income from "../veiws/Dashboard/Expense/Income/Income";
import Result from "../veiws/Website/Result/Result";
import Shoqa from "../veiws/Dashboard/Shoqa/Shoqa";
import Dashboard from "../veiws/Dashboard/Dashboard/Dashboard";
import MenuProfile from "../veiws/Dashboard/MenuProfile/MenuProfile";
import Contact from "../veiws/Website/Contact/Contact";
import Branch from "../veiws/Website/Branch/Branch";
import { SelectedData } from "../hooks/route/route";
import { useSelector } from "react-redux";
import { useState } from "react";
import { User_Data, } from "../hooks/Requests/localStroagedata";
import ErrorPage from "../components/ErrorPage/ErrorPage";
import StudentActivation from "../veiws/Dashboard/activation/student";
import TeacherActivation from "../veiws/Dashboard/activation/teacher";
import { Typography } from "@mui/material";
import UnderConstruction from "../components/underConstruction/underConstruction";
import StudentResult from "../veiws/Dashboard/studentResult/studentResult";
import StudentStatus from "../veiws/Dashboard/StudentStatus/StudentStatus";
import MobileApp from "../veiws/Dashboard/MobileApp";

// console.log(SelectedData())
export const RouteHeader = () => {
  // const [data , setData] = useState(useSelector((state)=>state.Auth.userdata.roles.StudentReg))
  // console.log(data + 'sami')
 // console.log(User_Data.roles[0])
 let getRolse = User_Data == undefined ? undefined : User_Data.roles[0]  == undefined ? 'empty' : User_Data.roles[0];
  return {
    router:{
        auth:[
            {path:'/auth/' , element: <Navigate to="/auth/login" /> },
            {path:'/auth/login' , element:<Login />},
            // {path:'/auth/registration', element:<Registration />},
           
        ],
        SinglePage:[
            {path:'/' , element: <Navigate to="/HomePage" /> },
            {path:'/HomePage' , element: <div id="HomePage"><Contact /></div>},
            {path:'/Result' , element: <div id="Result"><Result /></div>},
            // {path:'/Contact' , element: <div id="Result"><Contact /></div>},
            {path:'/Branch/:BranchId' , element: <div id="Result"><Branch /></div>},
            {
              item: true,
            id: uniqueId(),
            title: 'My-Profile',
            icon: IconCopy,
            // href: '/Private/Shoqa',
            path: '/*',
            element: <div id="ErrorPage"><ErrorPage /></div>
              
            }
        ],
        Menu:[
          ...(User_Data == undefined  ? [] : User_Data == 'empty' ? [] : [
            {path:'/Private/' , element: <Navigate to="/Private/Home" /> },
            // {path:'/Private/Home', element: <>Dashboard</>},
          
                  {
                    item: true,
                    navlabel: true,
                    subheader: <div className="mainMenuMain"><Typography variant="span" sx={{display: (theme)=>theme.palette.sidemenutext.display.display}}>Home </Typography><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>کورپاڼه</Typography></div>,
                    title: '',
                    icon: '',
                    href: '',
                    path: '',
                    element: ''
                  },
                  {
                    item: true,
                    navlabel: false,
                    subheader: '',
                    title: 'Dashboard',
                    icon: IconLayoutDashboard,
                    href: '/Private/Home',
                    path: '/Private/Home',
                    element: <div id="Dashboard"><Dashboard /></div>
                  },
              // ...( getRolse.lblStudent != 1
              ...( getRolse.StudentReg == 1 || getRolse.StudentAttendance == 1 || getRolse.StudentReport == 1 || getRolse.FeesCollection == 1 || getRolse.StudentActive == 1
                ?
                [
                  {
                    item: true,
                 navlabel: true,
                 subheader: <div className="mainMenuMain"><Typography variant="span" sx={{display: (theme)=>theme.palette.sidemenutext.display.display}}>Student </Typography><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>متعلم</Typography></div>,
               }]
               :
               []
              ),
              ...( getRolse.StudentReg != 1
                ? []
                :
                [
                  {
                    item: true,
                    id: uniqueId(),
                    title: <div><span>Registration </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>متعلم ثبت نام</Typography></div>,
                    icon: AppRegistration,
                    href: '/Private/Registration/Student',
                    path: '/Private/Registration/Student',
                    element: <div id="Registration"><Registration /></div>
                  },
                ]
                ),
                ...( getRolse.StudentAttendance != 1
                  ? []
                  :
                  [
              {
                item: true,
                id: uniqueId(),
                title: <div><span>Attendance </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> متعلم حاضری</Typography></div>,
                icon: FlightClassOutlined,
                href: '/Private/Attendance/Student',
                path: '/Private/Attendance/Student',
                element: <div id="StudentAttendance"><StudentAttendance /></div>
              }]),
              
              ...( getRolse.StudentReport != 1
                ? []
                :
                [{
                item: true,
                id: uniqueId(),
                title: <div><span>Report </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> متعلم راپور</Typography></div>,
                icon: Assessment,
                href: '/Private/Report/Student',
                path: '/Private/Report/Student',
                element: <div id="StudentReport"><StudentReport /></div>
              }]),
              ...( getRolse.FeesCollection != 1
                ? []
                :
                [{
                item: true,
                id: uniqueId(),
                title: <div><span>Fee Collection </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> فیس </Typography></div>,
                icon: AddCard,
                href: '/Private/collection/Fee',
                path: '/Private/collection/Fee',
                element: <div id="Fee"><Fee /></div>
              }]),
             
              
              // ...( getRolse.lblTeacher != 1
              ...( getRolse.TeacherReg == 1 || getRolse.TeacherAttendance == 1 || getRolse.TeacherReport == 1 ||  getRolse.SalaryDistribution == 1 || getRolse.StudentActive == 1
                ?
                [{
                item: true,
                navlabel: true,
                subheader: <div className="mainMenuMain"><Typography variant="span" sx={{display: (theme)=>theme.palette.sidemenutext.display.display}}>Teacher </Typography><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px'}}> معلم</Typography></div>,
              }]
              :
              []
              ),
              ...( getRolse.TeacherReg != 1
                ? []
                :
                [{
                item: true,
                id: uniqueId(),
                title: <div><span>Registration </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> معلم ثبت نام</Typography></div>,
                icon: AppRegistration,
                href: '/Private/Registration/Teacher',
                path: '/Private/Registration/Teacher',
                element: <div id="TechRegistration"><TechRegistration /></div>
              }]),
              ...( getRolse.TeacherAttendance != 1
                ? []
                :
                [{
                item: true,
                id: uniqueId(),
                title: <div><span>Attendance </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> معلم حاضری</Typography></div>,
                icon: FlightClassOutlined,
                href: '/Private/Attendance/Teacher',
                path: '/Private/Attendance/Teacher',
                element: <div id="TeacherAttendance"><TeacherAttendance /></div>
              }]),
              ...( getRolse.TeacherReport != 1
                ? []
                :
                [{
                item: true,
                id: uniqueId(),
                title: <div><span>Report </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> معلم راپور</Typography></div>,
                icon: Assessment,
                href: '/Private/Report/Teacher',
                path: '/Private/Report/Teacher',
                element: <div id="TeacherReport"><TeacherReport /></div>
              }]),
              ...( getRolse.SalaryDistribution != 1
                ? []
                :
                [{
                item: true,
                id: uniqueId(),
                title: <div><span>Salary Distribute </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> معاشیات اجرا</Typography></div>,
                icon: AddCard,
                href: '/Private/collection/Salary',
                path: '/Private/collection/Salary',
                element: <div id="Salary"><Salary /></div>
              }]),
              ...( getRolse.StudentActive != 1
                ? []
                :
                [{
                item: true,
                id: uniqueId(),
                title: <div><span>Activiation</span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>  متعلم فعالول</Typography></div>,
                icon: AddAlert,
                href: '/Private/Veiw/Students',
                path: '/Private/Veiw/Students',
                element: <div id="StudentActivation"><StudentActivation /></div>
              }]),
            
              ...( getRolse.Class == 1 || getRolse.Admin == 1 || getRolse.Shoqa == 1 ||  getRolse.TeacherActive == 1 || getRolse.Result == 1 || getRolse.StudentStatus == 1 || getRolse.Marif == 1 || getRolse.MobileApp == 1
                ?
                [{
                  item: true,
                  navlabel: true,
                  subheader: <div className="mainMenuMain"><Typography variant="span" sx={{display: (theme)=>theme.palette.sidemenutext.display.display}}>Managment </Typography><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>  مدیریت</Typography></div>,
                }]
                :
                []
                ),
              ...( getRolse.Class != 1
                ? []
                :
                [{
                item: true,
                id: uniqueId(),
                title: <div><span>Class </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> صنف </Typography></div>,
                icon: Subject,
                href: '/Private/Subjects',
                path: '/Private/Subjects',
                element: <div id="Subjects"><Subjects /></div>
              }]),
              ...( getRolse.Admin != 1
                ? []
                :
                [{
                item: true,
                id: uniqueId(),
                title: <div><span>Authorities </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> اختیارات   </Typography></div>,
                icon: Api,
                href: '/Private/Auth/Role',
                path: '/Private/Auth/Role',
                element: <div id="CustomizeRolls"><CustomizeRolls /></div>
              }]),
                  ...( getRolse.Shoqa != 1
                    ? []
                    :
                    [{
                  item: true,
                  id: uniqueId(),
                  title: <div><span>Shoqa</span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> شوقه</Typography></div>,
                  icon: Dialpad,
                  href: '/Private/Shoqa',
                  path: '/Private/Shoqa',
                  element: <div id="Shoqa"><Shoqa /></div>
                  }]),
                  ...( getRolse.TeacherActive != 1
                    ? []
                    :
                    [{
                    item: true,
                    id: uniqueId(),
                    title: <div><span>Activiation </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>  معلم فعالول </Typography></div>,
                    icon: AddAlert,
                    href: '/Private/Veiw/Teacher',
                    path: '/Private/Veiw/Teacher',
                    element: <div id="TeacherActivation"><TeacherActivation /></div>
                  }]),
                  ...( getRolse.MobileApp != 1
                    ? []
                    :
                    [{
                    item: true,
                    id: uniqueId(),
                    title: <div><span>Mobile App </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>موبایل اپلیکیشن</Typography></div>,
                    icon: InstallMobile,
                    href: '/Private/Veiw/Mobile',
                    path: '/Private/Veiw/Mobile',
                    element: <div id="MobileApp"><MobileApp /></div>
                  }]),
                  ...( getRolse.Result != 1
                    ? []
                    :
                    [{
                    item: true,
                    id: uniqueId(),
                    title: <div><span>Result Card </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>نتیجه کارډ</Typography></div>,
                    icon: StickyNote2Rounded,
                    href: '/Private/StudentResult',
                    path: '/Private/StudentResult',
                    element: <div id="StudentResult"><StudentResult /></div>
                  }]),
                  ...( getRolse.StudentStatus != 1
                    ? []
                    :
                    [{
                    item: true,
                    id: uniqueId(),
                    title: <div><span>Student Grade </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>متعلم درجه بندی</Typography></div>,
                    icon: GradeOutlined,
                    href: '/Private/StudentStatus',
                    path: '/Private/StudentStatus',
                    element: <div id="StudentStatus"><StudentStatus /></div>
                  }]),
                  ...( getRolse.Marif != 1
                    ? []
                    :
                    [{
                    item: true,
                    id: uniqueId(),
                    title: <div><span>Marif </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>معارف</Typography></div>,
                    icon: VerifiedUserRounded,
                    href: '/Private/Marif',
                    path: '/Private/Marif',
                    element: <div id="Marif"><UnderConstruction /></div>
                  }]),
                  ...( getRolse.Expense == 1 || getRolse.Donation == 1 || getRolse.Income == 1
                    ? 
                    [{
                    item: true,
                    navlabel: true,
                    subheader: <div className="mainMenuMain"><Typography variant="span" sx={{display: (theme)=>theme.palette.sidemenutext.display.display}}>Finance </Typography><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}>  ماليه</Typography></div>,
                  }]
                  :
                  []
                 
                  ),
                  ...( getRolse.Expense != 1
                    ? []
                    :
                    [{
                    item: true,
                    id: uniqueId(),
                    title: <div><span>Expense </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> لګښت</Typography></div>,
                    icon: CallSplit,
                    href: '/Private/Expense/Expense',
                    path: '/Private/Expense/Expense',
                    element: <div id="Expense"><Expense /></div>
                    }]),
                    ...( getRolse.Donation != 1
                      ? []
                      :
                      [{
                      item: true,
                      id: uniqueId(),
                      title: <div><span>Donation </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> بسپنه</Typography></div>,
                      icon: Bloodtype,
                      href: '/Private/Expense/Donation',
                      path: '/Private/Expense/Donation',
                      element: <div id="Donation"><Donation /></div>
                      }]),
                      ...( getRolse.Income != 1
                        ? []
                        :
                        [{
                      item: true,
                      id: uniqueId(),
                      title: <div><span>Income </span><Typography variant="span" sx={{fontSize: '16px',marginLeft: '7px',display: (theme)=>theme.palette.sidemenutext.display.display, display: 'inline'}}> عاید</Typography></div>,
                      icon: DataSaverOn,
                      href: '/Private/Expense/Income',
                      path: '/Private/Expense/Income',
                      element: <div id="Income"><Income /></div>
                      }]),
                   {
                    item: true,
                    id: uniqueId(),
                    title: 'My-Profile',
                    icon: IconCopy,
                    // href: '/Private/Shoqa',
                    path: '/Private/My-Profile',
                    element: <div id="Profile"><MenuProfile /></div>
                    },
                    {
                      item: true,
                    id: uniqueId(),
                    title: 'My-Profile',
                    icon: IconCopy,
                    // href: '/Private/Shoqa',
                    path: '/Private/*',
                    element: <div id="ErrorPage"><ErrorPage /></div>
                      
                    }
        ])]
    }
  }
}